.nav {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: center;
    
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
    background-color: white;
}
.nav a {
    font-size: 1.5rem;
    color: black;
}
.notActive {
    text-decoration: none;
    opacity: 70%;
}
.active {
    text-decoration: underline grey;
    opacity: 100%;
}
.navTitle, .mainTitle {
    font-size: 1.5rem;
    margin-right: 50%;
}