p {
    font-size: 0.9em;
}

.footer {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    min-height: 50px;

    border-color: black;
    border-style: solid;
}

.footerColums {
    display: flex;
    flex-direction: column;
}

.logo {
    max-width: 3rem;
    max-height: 3rem;
    margin-bottom: 1rem;
}

.footerTitle {
    font-size: 1rem;
    font-weight: bold;
}

.footerNav a {
    color: black;
}