.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Georgia, 'Times New Roman', Times, serif;

    height: 100vh;
}

.text-content {
    width: 30%;
}

.myName {
    font-size: 5vh;
    font-weight: bold;
}

.infoText {
    font-size: 1em;
    font-weight: 500;
}

.image {
    max-height: 50vh;
}

.pictureText {
    text-align: center;
    font-size: 0.5em;
}