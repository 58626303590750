.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100vh;
}

.title {
    font-size: 1.5em;
    font-family: Arial, Helvetica, sans-serif;
}

p {
    font-family: Georgia, 'Times New Roman', Times, serif;
}